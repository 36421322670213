import {
  BsCartDash,
  BsHouse,
  BsPerson,
  BsSpeedometer2,
  BsExclamationCircle,
  BsGearWideConnected,
  BsTools,
  BsEye,
} from "react-icons/bs";
import { BiCar } from "react-icons/bi";
export const PAGES = [
  {
    id: "home",
    title: "Inicio",
    ref: "/",
    icon: <BsHouse />,
  },
  {
    id: "clients",
    title: "Clientes",
    ref: "/clients",
    icon: <BsPerson />,
  },
  {
    id: "monitoreo",
    title: "Monitoreo",
    ref: "/monitoreo",
    icon: <BsSpeedometer2 />,
  },
  // {
  //   id: "facturacion",
  //   title: "Facturación",
  //   ref: "/facturacion",
  //   icon: <BsCurrencyDollar />,
  // },
  {
    id: "categorias",
    title: "Categorias",
    ref: "/categorias",
    icon: <BsGearWideConnected />,
  },
  {
    id: "productos",
    title: "Productos",
    ref: "/productos",
    icon: <BsCartDash />,
  },
  {
    id: "vehiculos",
    title: "Vehiculos",
    ref: "/vehiculos",
    icon: <BiCar />,
    subMenu: [
      {
        id: "consumos",
        title: "Consumos",
        ref: "/vehiculos/consumos",
        icon: <BsSpeedometer2 />,
      },
      {
        id: "reporte-falla",
        title: "Reporte de fallas",
        ref: "/vehiculos/reporte-falla",
        icon: <BsTools />,
      },

      {
        id: "visor-fin-viaje",
        title: "Reporte fin de viaje",
        ref: "/vehiculos/visor-fin-viaje",
        icon: <BsEye />,
      },
    ],
  },
  // {
  //   id: "solicitudes",
  //   title: "Solicitudes",
  //   ref: "/solicitudes",
  //   icon: <BiBuildings />,
  // },
  {
    id: "formularios-errores",
    title: "Errores WebHook",
    ref: "/formularios-errores",
    icon: <BsExclamationCircle />,
  },
];
