import React from "react";
import GRAFICOS_DEMO from "../../../assets/GRAFICOS_DEMO.png";
import { Button, Card, DatePicker, Form, Select, Table, Tabs } from "antd";
import { BsFileExcel } from "react-icons/bs";

const Detallado = () => {
  return (
    <div id="detallado">
      <div className="flex justify-between">
        <Form className="flex w-full items-center gap-2">
          <Form.Item label="Periodo">
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item label="Unidad">
            <Select className="w-56" />
          </Form.Item>
          <Button className="mb-6" htmlType="submit" type="primary">
            Filtrar
          </Button>
        </Form>
        <Button
          className="flex items-center bg-green-800 hover:bg-green-900 gap-2"
          type="primary"
        >
          <BsFileExcel /> Descargar informe
        </Button>
      </div>
      <div className="flex flex-wrap w-full justify-between">
        <div className="w-2/3 max-md:w-full">
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: "1",
                label: "Graficos",
                children: <img src={GRAFICOS_DEMO} alt="" />,
              },
              { key: "2", label: "Datos", children: <Table /> },
            ]}
          />
        </div>
        <div className="w-1/3 max-md:w-full">
          <Card className="mb-5 mt-10">
            <div className="flex flex-wrap gap-10">
              <div>
                <b>Lt consumidos</b>
                <p className="text-lg">20</p>
              </div>
              <div>
                <b>Km recorridos</b>
                <p className="text-lg">100</p>
              </div>
              <div>
                <b>Rendimiento</b>
                <p className="text-lg">5 km/l</p>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Detallado;
