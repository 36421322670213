import React from "react";
import LoginContainer from "../components/LoginContainer/LoginContainer";
import { Navigate } from "react-router-dom";

const LoginPage = ({ isSignedIn }: { isSignedIn: boolean }) => {
  return isSignedIn ? (
    <Navigate to={"/"} replace />
  ) : (
    <div className="w-screen h-screen flex justify-center items-center">
      <LoginContainer />
    </div>
  );
};

export default LoginPage;
