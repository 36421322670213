import { Button, Popconfirm, Table, Tooltip } from "antd";
import React, { useEffect } from "react";
import {
  BsExclamationTriangle,
  BsEye,
  BsListTask,
  BsPencilFill,
  BsPlusCircleFill,
  BsTrashFill,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import {
  deleteVehiculo,
  getVehiculos,
} from "../../redux/actions/vehiculos.actions";
import { useAppDispatch, useAppSelector } from "../../hooks";

import { FaTruck } from "react-icons/fa";

const Vehiculos = () => {
  const dispatch = useAppDispatch();
  const { vehiculos } = useAppSelector((state) => state);
  useEffect(() => {
    dispatch(getVehiculos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async (id: string) => {
    await dispatch(deleteVehiculo(id));
    dispatch(getVehiculos());
  };
  return (
    <div className="p-5">
      <h1 className="text-2xl text-green-800">Vehiculos</h1>

      <div className="flex gap-2 flex-wrap">
        <Link to={"/vehiculos/agregar"}>
          <Button type="primary" className="flex gap-2 mb-2">
            <BsPlusCircleFill className="mt-1 mr-1" />
            Agregar Vehiculo
          </Button>
        </Link>
        <Link to={"/vehiculos/reporte-colision"}>
          <Button
            type="primary"
            className="flex gap-2 mb-2 bg-red-600 hover:bg-red-900"
          >
            <BsExclamationTriangle className="mt-1 mr-1" />
            Reporte de colision
          </Button>
        </Link>
        <Link to={"/vehiculos/reporte-infraccion"}>
          <Button
            type="primary"
            className="flex gap-2 mb-2 bg-red-600 hover:bg-red-900"
          >
            <BsListTask className="mt-1 mr-1" />
            Reporte de infracciones
          </Button>
        </Link>
      </div>
      <Table
        loading={vehiculos.isLoading || vehiculos.isDeleteLoading}
        dataSource={vehiculos.vehiculos}
        columns={[
          { key: "id", dataIndex: "id", title: "Unidad" },
          {
            key: "estado",
            dataIndex: "estado",
            title: "Estatus",
            render: (value) => (
              <Tooltip
                title={
                  value === "FUNCIONANDO"
                    ? "Funcionando"
                    : value === "EN TALLER"
                    ? "Fallas en taller"
                    : "Deshabilitado temporalmente"
                }
              >
                <FaTruck
                  size={24}
                  className="mx-auto"
                  color={
                    value === "FUNCIONANDO"
                      ? "green"
                      : value === "EN TALLER"
                      ? "red"
                      : "gray"
                  }
                />
              </Tooltip>
            ),
          },
          { key: "marca", dataIndex: "marca", title: "Marca" },
          { key: "modelo", dataIndex: "modelo", title: "Modelo" },
          { key: "placas", dataIndex: "placas", title: "Placas" },
          {
            key: "niv",
            dataIndex: "niv",
            title: "Número de serie",
          },
          {
            key: "motor",
            dataIndex: "motor",
            title: "Número de motor",
          },
          {
            dataIndex: "id",
            title: "Acciones",
            render: (id, record, index) => {
              return (
                <div className="flex gap-2">
                  <Tooltip title="Editar camioneta" placement="left">
                    <Link to={`/vehiculos/${id}`}>
                      <Button type="primary">
                        <BsPencilFill />
                      </Button>
                    </Link>
                  </Tooltip>

                  <Tooltip title="Ver expediente" placement="left">
                    <Link to={`/vehiculos/expediente/${id}`}>
                      <Button
                        type="primary"
                        className="bg-slate-700 hover:bg-slate-900"
                      >
                        <BsEye />
                      </Button>
                    </Link>
                  </Tooltip>

                  <Tooltip title="Eliminar" placement="right">
                    <Popconfirm
                      title="Seguro que deseas borrar la unidad"
                      onConfirm={() => handleDelete(id)}
                    >
                      <Button
                        type="primary"
                        className="bg-red-700 hover:bg-red-900"
                      >
                        <BsTrashFill />
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default Vehiculos;
