import React from "react";
import "antd/dist/reset.css";
import "./App.css";
import locale from "antd/locale/es_ES";
import { persistore, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import RouterComponent from "./pages/RouterComponent";
import dayjs from "dayjs";
import "dayjs/locale/es-mx";

dayjs.locale("es-mx");

Amplify.configure(awsconfig);

function App() {
  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          colorPrimary: "#00b96b",
        },
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistore}>
          <RouterComponent />
        </PersistGate>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
