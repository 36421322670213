import { Button, notification, Tabs } from "antd";
import moment from "moment";
import React, { ChangeEvent, useRef, useState } from "react";
import { BiGasPump } from "react-icons/bi";
import RendimientoGeneral from "./RendimientoGeneral";
import Detallado from "./Detallado";
import ExcelJS from "exceljs";
import { API_BASE_URL } from "../../../utils/api";

const ENCABEZADOS_ARCHIVO_KINZA = [
  null,
  "IDENTIFICADOR",
  "PLACAS",
  "MARCA",
  "MODELO",
  "PRODUCTO",
  "PRECIO",
  "CANTIDAD",
  "IMPORTE",
  "TICKET",
  "E.CARGA",
  "KM",
  "FECHA",
];

const Consumos = () => {
  const [archivoKinzaLoading, setArchivoKinzaLoading] = useState(false);
  const [inputFileKey, setInputFileKey] = useState(1);
  const kinzaFile = useRef<HTMLInputElement | null>(null);

  const uploadKinzaExcel = () => {
    setArchivoKinzaLoading(true);
    kinzaFile.current?.click();
  };

  const onKinzaFileSelected = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      setArchivoKinzaLoading(false);
      return;
    }

    const file = input.files[0];
    const extension = file.name.split(".")[1];
    if (extension && extension !== "xlsx") {
      notification.error({
        message:
          "Tipo de archivo no permitido, solo se admiten archivos tipo xlsx",
      });
      setArchivoKinzaLoading(false);
      return;
    }
    const reader = new FileReader();
    reader.onerror = (e) => {
      console.error("Error reading file:", e);
    };
    reader.onload = async (e) => {
      const buffer = e.target?.result;
      if (!buffer || typeof buffer === "string") {
        notification.error({
          message: "Error al leer archivo, error desconocido",
        });
        setArchivoKinzaLoading(false);
        return;
      }
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);
      // Acceder a las hojas de trabajo y datos
      const gasolinasSheet = workbook.worksheets[0];
      const gasolinasRows: any[] = [];

      gasolinasSheet.eachRow((row, rowIndex) => {
        if (Array.isArray(row.values)) {
          if (rowIndex === 1) {
            if (
              !(
                row.values.length === ENCABEZADOS_ARCHIVO_KINZA.length &&
                row.values.every(
                  (value, index) => value === ENCABEZADOS_ARCHIVO_KINZA[index]
                )
              )
            ) {
              notification.error({
                message:
                  "Error en el archivo, recuerda modificar el archivo KINZA: - Los encabezados deben ir en la primer fila - El archivo debe ser extension XLSX ",
              });
              setArchivoKinzaLoading(false);
              throw new Error("Error en el archivo");
            }
          } else {
            const parsedDate = moment(
              row.values[12]?.toString().replaceAll("/", "-")
            )
              .utcOffset(0)
              .format("YYYY-MM-DD hh:mm:ss");
            const payload = {
              placas: row.values[2]?.toString().replaceAll(" ", ""),
              unidad: row.values[3]?.toString().split("-")[0],
              tipoGasolina: row.values[5]?.toString(),
              litros: row.values[7]?.toString(),
              precio: row.values[6]?.toString(),
              total: row.values[8]?.toString(),
              fecha: parsedDate,
            };
            gasolinasRows.push(payload);
          }
        }
      });
      await fetch(`${API_BASE_URL}/vehiculos/kinza`, {
        method: "POST",
        body: JSON.stringify(gasolinasRows),
      });

      notification.success({ message: "Gasolinas importadas con éxito" });
    };

    reader.readAsArrayBuffer(file);
    setInputFileKey(inputFileKey + 1);
    setArchivoKinzaLoading(false);
  };

  return (
    <div className="p-5">
      <input
        type="file"
        key={inputFileKey}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ref={kinzaFile}
        style={{ display: "none" }}
        onChange={(event) => onKinzaFileSelected(event)}
      />
      <div className="flex justify-between">
        <h1 className="text-2xl text-green-800">Consumos</h1>
        <div className="text-right text-sm ">
          Gasolinas actualizadas al día:{" "}
          <strong>{moment("2024-12-31").format("LLL")}</strong>
          <br />
          Kilometrajes actualizadas al día:{" "}
          <strong>{moment("2024-12-31").format("LLL")}</strong>
        </div>
      </div>

      <div id="acciones" className="flex gap-2">
        <Button
          className="flex items-center gap-2"
          type="primary"
          onClick={uploadKinzaExcel}
          loading={archivoKinzaLoading}
        >
          <BiGasPump /> Subir archivo KINZA
        </Button>
        <Button
          className="flex items-center gap-2 bg-emerald-700 hover:bg-emerald-600"
          type="primary"
        >
          <BiGasPump /> Carga gasolinas manual
        </Button>
        <Button
          className="flex items-center gap-2 bg-teal-700 hover:bg-teal-600"
          type="primary"
        >
          <BiGasPump /> Carga Diesel manual
        </Button>
      </div>

      <div id="dashboard" className="py-5">
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: "1",
              label: "Rendimiento general",
              children: <RendimientoGeneral />,
            },
            {
              key: "2",
              label: "Detallado por unidad",
              children: <Detallado />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Consumos;
