import { Button, DatePicker, Divider, Form, Input, message, Spin } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getVehiculos,
  saveVehiculo,
} from "../../../redux/actions/vehiculos.actions";
import dayjs from "dayjs";
interface IProps {
  vehiculoId?: string;
  onFinish: (id?: string) => void;
}
const Step1 = ({ vehiculoId, onFinish }: IProps) => {
  const [form] = useForm();
  const {
    vehiculos: { currentVehiculo, isSaveLoading, isLoadingOne },
  } = useAppSelector((state) => state);
  const editMode = vehiculoId !== undefined;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (editMode) {
      dispatch(getVehiculos(vehiculoId));
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const values = form.getFieldsValue();
    try {
      await dispatch(saveVehiculo(values, editMode));
      message.success("Unidad guardad con éxito");
      onFinish?.(values.id);
    } catch (error) {}
  };

  return isLoadingOne ? (
    <Spin />
  ) : (
    <Form
      onFinish={handleSubmit}
      form={form}
      initialValues={
        currentVehiculo
          ? {
              ...currentVehiculo,
              fechaSeguroInicio: currentVehiculo.fechaSeguroInicio
                ? dayjs(currentVehiculo.fechaSeguroInicio)
                : null,
              fechaSeguroFin: currentVehiculo.fechaSeguroFin
                ? dayjs(currentVehiculo.fechaSeguroFin)
                : null,
            }
          : null
      }
    >
      <Divider orientation="left">Información general</Divider>
      <div className="flex justify-between gap-2">
        <Form.Item
          className="w-full"
          label="Unidad"
          name="id"
          rules={[{ required: true, message: "Número economico requerido" }]}
        >
          <Input placeholder="Número economico" disabled={editMode} />
        </Form.Item>
        <Form.Item
          className="w-full"
          label="Año"
          name="modelo"
          rules={[{ required: true, message: "Modelo requerido" }]}
        >
          <Input placeholder="2024" maxLength={4} />
        </Form.Item>
        <Form.Item
          className="w-full"
          label="Placas"
          name="placas"
          rules={[{ required: true, message: "Placas requeridas" }]}
        >
          <Input placeholder="JW23903" maxLength={7} />
        </Form.Item>
        <Form.Item
          className="w-full"
          label="Marca"
          name="marca"
          rules={[{ required: true, message: "Marca requerida" }]}
        >
          <Input placeholder="NISSAN NP300" />
        </Form.Item>
      </div>
      <div className="flex justify-between gap-2">
        <Form.Item className="w-full" label="Número de serie" name="niv">
          <Input placeholder="3N6DD25X3FK083929" maxLength={17} />
        </Form.Item>
        <Form.Item className="w-full" label="Número de motor" name="motor">
          <Input placeholder="KA24858340A" maxLength={13} />
        </Form.Item>
        <Form.Item
          className="w-full"
          label="Nombre en la factura"
          name="nombreFactura"
        >
          <Input placeholder="CARLOS SANTANA RUELAS" />
        </Form.Item>
      </div>
      <Divider orientation="left">Seguro</Divider>
      <div className="flex justify-between gap-2">
        <Form.Item className="w-full" label="Compañia" name="cia">
          <Input placeholder="BBVA, CHUBB, QUALITAS..." />
        </Form.Item>
        <Form.Item className="w-full" label="No. de poliza" name="poliza">
          <Input placeholder="f1234567" />
        </Form.Item>
        <Form.Item className="w-full" label="No. de enciso" name="enciso">
          <Input placeholder="1" />
        </Form.Item>
      </div>
      <div className="flex justify-start gap-2">
        <Form.Item
          className="w-full"
          label="Inicio de la poliza"
          name="fechaSeguroInicio"
        >
          <DatePicker format={"DD-MM-YYYY"} />
        </Form.Item>
        <Form.Item
          className="w-full"
          label="Fin de la poliza"
          name="fechaSeguroFin"
        >
          <DatePicker format={"DD-MM-YYYY"} />
        </Form.Item>
      </div>
      <Button type="primary" htmlType="submit" loading={isSaveLoading}>
        Siguiente
      </Button>
    </Form>
  );
};

export default Step1;
