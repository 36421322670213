import { Button, Modal, Table } from "antd";
import { Key, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  deleteForm,
  fetchFormulariosConErrores,
  saveNewManifiesto,
} from "../redux/actions/formulariosConErrores.actions";
import moment from "moment";
import EditorFormulariosConErrores from "../components/EditorFormulariosConErrores";
import formatManifiestoPayload from "../utils/formatManifiestoPayload";
import { BsEye, BsTrash } from "react-icons/bs";

const FormulariosConErrores = () => {
  /**Utils **********/
  const dispatch = useAppDispatch();

  /**Inner State ****************/
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [formularioConErroresEditar, setFormularioConErroresEditar] =
    useState<any>({});
  const [formularioIdEdicion, setFormularioIdEdicion] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  /**Redux *********************/
  const isLoading = useAppSelector(
    (state) => state.formulariosErrores.isLoading
  );
  const formularios = useAppSelector(
    (state) => state.formulariosErrores.formularios
  );

  /**Effects *******************/
  useEffect(() => {
    dispatch(fetchFormulariosConErrores());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenFormulario = async (formularioConErrores: any) => {
    // Format FORMULARIO
    const manifiesto = await formatManifiestoPayload(
      formularioConErrores.payload
    );
    setFormularioIdEdicion(formularioConErrores.id);
    setFormularioConErroresEditar(manifiesto);
    setIsEditorOpen(true);
  };

  const handleDeleteFormulario = async (record: any) => {
    const formularioId = record.id;
    const confirmacion = window.confirm("¿Seguro que deseas eliminarlo?");
    if (confirmacion) {
      await dispatch(deleteForm(formularioId));
      await dispatch(fetchFormulariosConErrores());
    }
  };

  const handleSaveNewManifiesto = async (values: any) => {
    const result = await dispatch(
      saveNewManifiesto(formularioIdEdicion, values)
    );
    setIsEditorOpen(result);
    await dispatch(fetchFormulariosConErrores());
  };

  const handleDeleteManyFormularios = async () => {
    const confirmacion = window.confirm("¿Seguro que deseas eliminarlo?");
    if (confirmacion) {
      for (const formularioId of selectedRowKeys) {
        await dispatch(deleteForm(+formularioId));
      }
      await dispatch(fetchFormulariosConErrores());
      setSelectedRowKeys([]);
    }
  };

  return (
    <div className="p-5">
      {isEditorOpen && (
        <Modal
          open={isEditorOpen}
          closable={true}
          onCancel={() => setIsEditorOpen(false)}
          destroyOnClose
          footer={<></>}
          title="Información rescatada del formulario"
        >
          <EditorFormulariosConErrores
            manifiesto={formularioConErroresEditar}
            onFinishEdit={handleSaveNewManifiesto}
          />
        </Modal>
      )}
      <h1 className="text-2xl text-green-800">Formularios con errores</h1>
      <Button
        disabled={selectedRowKeys.length === 0}
        type="primary"
        danger
        className="mb-2"
        onClick={handleDeleteManyFormularios}
      >
        Eliminar seleccionados
      </Button>
      <Table
        rowSelection={{
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
          },
          selectedRowKeys: selectedRowKeys,
        }}
        rowKey={"id"}
        loading={isLoading}
        dataSource={formularios}
        pagination={{ pageSize: 1000 }}
        scroll={{ y: `calc(100vh - 250px)` }}
        columns={[
          { title: "id", dataIndex: "id", key: "id" },
          {
            title: "Folio",
            dataIndex: "payload",
            sorter: {
              compare: (a, b) => {
                return String(
                  a.payload?.extra_data?.order_data[0].order_code
                ).localeCompare(
                  b.payload?.extra_data?.order_data[0].order_code
                );
              },
            },
            render: (payload: any) => {
              if (
                !(
                  payload?.extra_data?.order_data &&
                  Array.isArray(payload?.extra_data?.order_data)
                )
              ) {
                return "No encontrado";
              }

              return payload.extra_data.order_data[0].order_code;
            },
          },
          {
            title: "Fecha y hora",
            dataIndex: "payload",
            render: (payload: any) => (
              <>
                {payload.date_time && moment(payload.date_time).format("LLL")}
              </>
            ),
          },
          {
            title: "# de cliente",
            dataIndex: "payload",
            render: (payload: any) => <>{payload.extra_data.address_code}</>,
          },
          {
            title: "Tipo",
            dataIndex: "payload",
            render: (payload: any) => payload.checklist_type,
          },
          {
            title: "Acciones",
            dataIndex: "payload",
            render: (payload: any, record: any) => (
              <>
                {payload.checklist_type === "on_visit" && (
                  <>
                    <button
                      className="bg-indigo-600 text-white hover:bg-indigo-500  p-2 rounded-md"
                      onClick={() => handleOpenFormulario(record)}
                    >
                      <BsEye />
                    </button>
                    <button
                      className="bg-red-600 text-white hover:bg-red-500 p-2 rounded-md ml-1"
                      onClick={() => handleDeleteFormulario(record)}
                    >
                      <BsTrash />
                    </button>
                  </>
                )}
              </>
            ),
          },
        ]}
      />
    </div>
  );
};

export default FormulariosConErrores;
