import { Button, DatePicker, Form, Table } from "antd";
import React from "react";
import { BsFileExcel } from "react-icons/bs";

const RendimientoGeneral = () => {
  return (
    <div id="rendimiento-general">
      <div className="flex justify-between">
        <Form className="flex flex-wrap gap-5">
          <Form.Item label="Periodo">
            <DatePicker.RangePicker />
          </Form.Item>
          <Button className="mb-6" htmlType="submit" type="primary">
            Filtrar
          </Button>
        </Form>
        <Button
          className="flex items-center bg-green-800 hover:bg-green-900 gap-2"
          type="primary"
        >
          <BsFileExcel /> Descargar informe
        </Button>
      </div>
      <Table
        columns={[
          { title: "Unidad" },
          { title: "Combustible" },
          { title: "Kilometros recorridos" },
          { title: "Litros consumidos" },
          { title: "Rendimiento" },
        ]}
      />
    </div>
  );
};

export default RendimientoGeneral;
