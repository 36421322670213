import { combineReducers, configureStore } from "@reduxjs/toolkit";
import ClientsReducer from "./reducers/clients";
import ProductsReducer from "./reducers/productos";
import LoginReducer from "./reducers/login";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import FormulariosConErroresReducer from "./reducers/formulariosConErrores";
import TiposFacturacionReducer from "./reducers/tiposFacturacion";
import ListaPreciosReducer from "./reducers/listaPrecios";
import PrefacturasReducer from "./reducers/prefacturas";
import ManifiestosReducer from "./reducers/manifiestos";
import VehiculosReducer from "./reducers/vehiculos";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const appReducer = combineReducers({
  manifiestos: ManifiestosReducer,
  clients: ClientsReducer,
  products: ProductsReducer,
  formulariosErrores: FormulariosConErroresReducer,
  tiposFacturacion: TiposFacturacionReducer,
  listaPrecios: ListaPreciosReducer,
  auth: LoginReducer,
  prefacturas: PrefacturasReducer,
  vehiculos: VehiculosReducer,
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistore = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
