import React, { useEffect, useState } from "react";
import { Button, Divider, Spin } from "antd";
import S3Uploader from "../../../components/S3Uploader";
import getS3Instance from "../../../utils/s3";

interface IProps {
  vehiculoId?: string;
  onFinish: () => void;
  onBack: () => void;
}
const Step2 = ({ vehiculoId, onFinish, onBack }: IProps) => {
  const initialDocumentUrlsObject: { [key: string]: boolean } = {
    polizaSeguro: false,
    refrendo: false,
    tarjetaCirculacion: false,
    Frontal: false,
    Derecha: false,
    Izquierda: false,
    Trasera: false,
  };
  const [documentUrlsObject, setDocumentUrlsObject] = useState(
    initialDocumentUrlsObject
  );
  const [documentsURLoading, setDocumentsURLoading] = useState(true);
  useEffect(() => {
    const getDocuments = async () => {
      const documentUrls = initialDocumentUrlsObject;
      const s3 = getS3Instance();
      const result = await s3
        .listObjectsV2({
          Bucket: process.env.REACT_APP_AWS_BUCKET_VEHICULOS || "",
          Prefix: `documentacion/${vehiculoId}/`,
        })
        .promise();

      if (result && result.Contents && result.Contents.length > 0) {
        for (let i = 0; i < result.Contents.length; i++) {
          const documentFilename: string | undefined =
            result.Contents[i].Key?.split("/")[2];
          if (documentFilename && documentFilename in documentUrls) {
            documentUrls[documentFilename] = true;
          }
        }
      }

      setDocumentUrlsObject(documentUrls);
      setDocumentsURLoading(false);
    };

    setDocumentsURLoading(true);
    getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiculoId]);

  return documentsURLoading ? (
    <Spin />
  ) : (
    <>
      <Divider>Documentación</Divider>
      <div className="w-full flex justify-between gap-2">
        <S3Uploader
          bucket={process.env.REACT_APP_AWS_BUCKET_VEHICULOS}
          fileName={`documentacion/${vehiculoId}/polizaSeguro`}
          fileFound={documentUrlsObject["polizaSeguro"]}
          label="Poliza de seguro"
        />
        <S3Uploader
          bucket={process.env.REACT_APP_AWS_BUCKET_VEHICULOS}
          fileName={`documentacion/${vehiculoId}/refrendo`}
          fileFound={documentUrlsObject["refrendo"]}
          label="Ultimo refrendo"
        />
        <S3Uploader
          bucket={process.env.REACT_APP_AWS_BUCKET_VEHICULOS}
          fileName={`documentacion/${vehiculoId}/tarjetaCirculacion`}
          fileFound={documentUrlsObject["tarjetaCirculacion"]}
          label="Tarjeta de circulación"
        />
      </div>
      <Divider>Fotos</Divider>
      <div className="w-full flex justify-between gap-2">
        <S3Uploader
          bucket={process.env.REACT_APP_AWS_BUCKET_VEHICULOS}
          fileName={`documentacion/${vehiculoId}/Frontal`}
          fileFound={documentUrlsObject["Frontal"]}
          label="Frontal"
        />
        <S3Uploader
          bucket={process.env.REACT_APP_AWS_BUCKET_VEHICULOS}
          fileName={`documentacion/${vehiculoId}/Derecha`}
          fileFound={documentUrlsObject["Derecha"]}
          label="Derecha"
        />
        <S3Uploader
          bucket={process.env.REACT_APP_AWS_BUCKET_VEHICULOS}
          fileName={`documentacion/${vehiculoId}/Izquierda`}
          fileFound={documentUrlsObject["Izquierda"]}
          label="Izquierda"
        />
        <S3Uploader
          bucket={process.env.REACT_APP_AWS_BUCKET_VEHICULOS}
          fileName={`documentacion/${vehiculoId}/Trasera`}
          fileFound={documentUrlsObject["Trasera"]}
          label="Trasera"
        />
      </div>
      <div className="flex gap-2">
        <Button onClick={onBack}>Anterior</Button>
        <Button type="primary" onClick={onFinish}>
          Siguiente
        </Button>
      </div>
    </>
  );
};

export default Step2;
