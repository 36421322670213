import { message, Upload, UploadFile } from "antd";
import React, { useState } from "react";
import { deleteFile, getSignedUrl, saveNewFile } from "../../utils/s3";
import { UploadOutlined } from "@ant-design/icons";

interface IProps {
  fileName: string;
  label: string;
  fileFound: boolean;
  bucket: string | undefined;
}
const S3Uploader = ({ fileName, label, fileFound, bucket }: IProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>(
    fileFound
      ? [
          {
            uid: `1`,
            name: fileName,
            status: "done",
            thumbUrl:
              "https://documentos-centinela.s3.amazonaws.com/document.png",
          },
        ]
      : []
  );

  const handleUpload = async ({
    file,
    onError,
    onSuccess,
    onProgress,
  }: any) => {
    if (
      typeof file === "string" ||
      onProgress === undefined ||
      onError === undefined ||
      onSuccess === undefined
    )
      return;
    const fileType = file.type;
    try {
      await saveNewFile(fileName, file, fileType, bucket);
      if (file) {
        file.thumbUrl =
          "https://documentos-centinela.s3.amazonaws.com/document.png";
        setFileList([file]);
      }
      onSuccess("");
      message.success(`Archivo subido con éxito`);
    } catch (error: any) {
      onError(error);
    }
  };

  const handleDownload = async () => {
    const url = await getSignedUrl(`${fileName}`, bucket);
    var link = document.createElement("a");
    link.target = "_blank";
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteFile = async () => {
    await deleteFile(`${fileName}`);
    setFileList([]);
    message.success(`Eliminado con exito`);
  };

  return (
    <div className="w-full">
      <h1>{label}</h1>
      <Upload
        listType="picture-card"
        maxCount={1}
        showUploadList={true}
        fileList={fileList}
        customRequest={handleUpload}
        onPreview={handleDownload}
        onRemove={handleDeleteFile}
      >
        {fileList.length >= 1 ? null : (
          <div>
            <UploadOutlined />
            <div style={{ marginTop: 8 }}>{label}</div>
          </div>
        )}
      </Upload>
    </div>
  );
};

export default S3Uploader;
