import { Skeleton } from "antd";
import React from "react";

const ExpedienteSkeleton = () => {
  return (
    <>
      <div className="flex">
        <div className="w-1/4 ">
          <Skeleton.Image active />
        </div>
        <Skeleton className="w-3/4" active />
      </div>
      <Skeleton active></Skeleton>
      <Skeleton active></Skeleton>
      <Skeleton active></Skeleton>
    </>
  );
};

export default ExpedienteSkeleton;
