import { Dispatch } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../utils/api";
import { message } from "antd";
export const ActionTypes = {
  SET_WIZARD_STEP: "SET_WIZARD_STEP",
  // FETCH ALL VEHICULOS
  START_FETCH_VEHICULOS: "START_FETCH_VEHICULOS",
  SUCCESS_FETCH_VEHICULOS: "SUCCESS_FETCH_VEHICULOS",
  FAILED_FETCH_VEHICULOS: "FAILED_FETCH_VEHICULOS",
  // FETCH ONE VEHICULO
  START_FETCH_ONE_VEHICULO: "START_FETCH_ONE_VEHICULO",
  SUCCESS_FETCH_ONE_VEHICULO: "SUCCESS_FETCH_ONE_VEHICULO",
  FAILED_FETCH_ONE_VEHICULO: "FAILED_FETCH_ONE_VEHICULO",

  // SAVE VEHICULO
  START_SAVE_VEHICULO: "START_SAVE_VEHICULO",
  SUCCESS_SAVE_VEHICULO: "SUCCESS_SAVE_VEHICULO",
  FAILED_SAVE_VEHICULO: "FAILED_SAVE_VEHICULO",
  // DELETE VEHICULO
  START_DELETE_VEHICULO: "START_DELETE_VEHICULO",
  SUCCESS_DELETE_VEHICULO: "SUCCESS_DELETE_VEHICULO",
  FAILED_DELETE_VEHICULO: "FAILED_DELETE_VEHICULO",
};

export const getVehiculos =
  (vehiculoId?: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: vehiculoId
        ? ActionTypes.START_FETCH_ONE_VEHICULO
        : ActionTypes.START_FETCH_VEHICULOS,
    });
    try {
      const response = await fetch(
        `${API_BASE_URL}/vehiculos${vehiculoId ? `/${vehiculoId}` : ""}`,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        dispatch({
          type: ActionTypes.FAILED_FETCH_VEHICULOS,
          payload: [response],
        });
        return;
      }
      const data = await response.json();
      dispatch({
        type: vehiculoId
          ? ActionTypes.SUCCESS_FETCH_ONE_VEHICULO
          : ActionTypes.SUCCESS_FETCH_VEHICULOS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.FAILED_FETCH_VEHICULOS,
        payload: [error],
      });
    }
  };

export const saveVehiculo =
  (payload: any, isUpdate: boolean) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.START_SAVE_VEHICULO,
    });
    try {
      let URL = `${API_BASE_URL}/vehiculos/${isUpdate ? payload.id : ""}`;
      const response = await fetch(URL, {
        method: isUpdate ? "PUT" : "POST",
        body: JSON.stringify(payload),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        message.error(`${response.status} - ${response.statusText}`);

        dispatch({
          type: ActionTypes.FAILED_SAVE_VEHICULO,
          payload: [`${response.status} - ${response.statusText}`],
        });
        const fullError = await response.json();
        console.error(JSON.stringify(fullError));
        throw new Error(fullError);
      }
      const data = await response.json();
      dispatch({ type: ActionTypes.SUCCESS_SAVE_VEHICULO, payload: data });
    } catch (error) {
      dispatch({
        type: ActionTypes.FAILED_SAVE_VEHICULO,
        payload: [error],
      });
      throw new Error(`${error}`);
    }
  };

export const deleteVehiculo = (id: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: ActionTypes.START_DELETE_VEHICULO,
  });
  try {
    const response = await fetch(`${API_BASE_URL}/vehiculos/${id}`, {
      method: "DELETE",
      headers: new Headers({ "content-type": "application/json" }),
    });
    if (!response.ok) {
      dispatch({
        type: ActionTypes.FAILED_DELETE_VEHICULO,
        payload: [response],
      });
    } else {
      const data = await response.json();
      dispatch({
        type: ActionTypes.SUCCESS_DELETE_VEHICULO,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILED_DELETE_VEHICULO,
      payload: [error],
    });
  }
};

export const setWizardStep = (step: number) => (dispatch: Dispatch) => {
  dispatch({
    type: ActionTypes.SET_WIZARD_STEP,
    payload: step,
  });
};
