import { Button, DatePicker, Form, Input, Select } from "antd";
import React from "react";

const ReporteFalla = () => {
  return (
    <div className="p-5">
      <h1 className="text-2xl text-green-800">Reporte falla</h1>
      <Form>
        <div className="grid grid-cols-3 gap-2">
          <div>
            <strong>Fecha</strong>
            <Form.Item name="Fecha">
              <DatePicker className="w-full" />
            </Form.Item>
          </div>
          <div>
            <strong>Unidad</strong>
            <Form.Item name="unidad">
              <Select placeholder="Unidad" />
            </Form.Item>
          </div>
          <div>
            <strong>¿Quién reporta?</strong>
            <Form.Item name="reporta">
              <Input placeholder="Luis Santana" />
            </Form.Item>
          </div>
        </div>
        <div>
          <strong>¿Que se reporta?</strong>
          <Form.Item name="reporta">
            <Input.TextArea className="w-full" rows={6} />
          </Form.Item>
        </div>
        <Button type="primary" htmlType="submit">
          Reportar falla
        </Button>
      </Form>
    </div>
  );
};

export default ReporteFalla;
