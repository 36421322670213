import React from "react";
import { Steps } from "antd";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setWizardStep } from "../../../redux/actions/vehiculos.actions";

const WizardVehiculo = () => {
  const { id } = useParams();
  const {
    vehiculos: { wizardStep },
  } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const prev = () => {
    dispatch(setWizardStep(wizardStep - 1));
  };

  const next = async (vehiculoId?: string) => {
    if (vehiculoId) {
      navigate(`/vehiculos/${vehiculoId}`);
    }
    dispatch(setWizardStep(wizardStep + 1));
  };
  const steps = [
    {
      title: "Datos del vehiculo",
      content: <Step1 vehiculoId={id} onFinish={next} />,
    },
    {
      title: "Documentos digitales",
      content: <Step2 vehiculoId={id} onFinish={next} onBack={prev} />,
    },
    {
      title: "Confirmar datos",
      content: <Step3 vehiculoId={id} onBack={prev} />,
    },
  ];

  return (
    <div className="p-5">
      <h1 className="text-2xl text-green-800">Agregar vehiculo</h1>
      <Steps current={wizardStep} items={steps} />
      <div className="pt-5">{steps[wizardStep].content}</div>
    </div>
  );
};

export default WizardVehiculo;
