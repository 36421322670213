import { Button, message } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
interface IProps {
  vehiculoId?: string;
  onBack: () => void;
}
const Step3 = ({ onBack }: IProps) => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    message.success("Unidad guardada con éxito");
    navigate("/vehiculos");
  };
  return (
    <div className="flex gap-2">
      <Button onClick={onBack}>Anterior</Button>
      <Button type="primary" onClick={handleSubmit}>
        Terminar
      </Button>
    </div>
  );
};

export default Step3;
