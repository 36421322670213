import {
  BsCheck,
  BsDownload,
  BsEnvelope,
  BsEye,
  BsFileExcelFill,
  BsSearch,
  BsPersonX,
  BsX,
  BsTable,
  BsPrinter,
  BsRecycle,
} from "react-icons/bs";
import {
  Button,
  Modal,
  Progress,
  Table,
  TableColumnType,
  Tooltip,
  notification,
} from "antd";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { useEffect, useState } from "react";
import {
  aprobarManifiesto,
  downloadMultiplePDF,
  downloadPDF,
  fetchPDF,
  getManifiestos,
  openPDFViewer,
  sendPDFByEmail,
  setOrderByState,
  setPaginationState,
} from "../../redux/actions/manifiestos.actions";
import moment from "moment";
import "moment/locale/es";
import { ManifiestoWithRecolecciones } from "../../redux/reducers/manifiestos";
import EditorManifiestos from "../EditorManifiestos/EditorManifiestos";
import CreadorManifiestos from "../EditorManifiestos/CreadorManifiestos";
import ExcelReportGenerator from "./ExcelReportGenerator";
import ColumnsSelector, { allOptions } from "./ColumnsSelector";
import { useLocation } from "react-router-dom";
moment.locale("es");

const ManifiestosTable = () => {
  /** UTILS */
  const dispatch = useAppDispatch();
  const { pathname: location } = useLocation();
  /** REDUX */
  const {
    manifiestos: {
      isSendingEmails,
      isLoading,
      isLoadingPDF,
      manifiestos: orders,
      manifiestosCount,
      filters: { aprobados: showAprobados },
      pageSize,
      currentPage,
      emailsPercent,
    },
  } = useAppSelector((state) => state);

  const hasManifiestos = useAppSelector(
    (state) => state.manifiestos.manifiestos.length > 0
  );
  /** INNER STATE */
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isCreatorOpen, setIsCreatorOpen] = useState(false);
  const [direction, setDirection] = useState<"ASC" | "DESC">("ASC");
  const [isExcelReportGeneratorOpen, setIsExcelReportGeneratorOpen] =
    useState(false);
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const [manifiestoToEdit, setManifiestoToEdit] = useState<
    ManifiestoWithRecolecciones | undefined
  >(undefined);
  const [userHiddenColumns, setUserHiddenColumns] = useState<string[]>([]);

  /** Effects */
  useEffect(() => {
    const uHiddenColumns = JSON.parse(
      localStorage.getItem(
        location === "/" ? "approvedHiddenColumns" : "nonApprovedHiddenColumns"
      ) || "[]"
    );
    if (uHiddenColumns) {
      setUserHiddenColumns(uHiddenColumns);
    }
  }, [location]);

  /** Handlers */
  const handlePageChange = (page: number, pageSize: number) => {
    dispatch(setPaginationState(page - 1, pageSize));
    dispatch(getManifiestos());
  };
  const handleDownloadPDF = (order: any, keepInPage: boolean) => {
    dispatch(downloadPDF(order, keepInPage));
  };
  const viewPDF = (order: any) => {
    dispatch(fetchPDF([order]));
    dispatch(openPDFViewer({ open: true }));
  };
  const handleDownloadAll = async () => {
    await dispatch(downloadMultiplePDF());
    dispatch(getManifiestos());
  };
  const handleSendEmails = async (manifiesto?: ManifiestoWithRecolecciones) => {
    if (manifiesto) {
      await dispatch(sendPDFByEmail(manifiesto));
      notification.success({ message: "Emails enviados" });
    } else {
      await dispatch(sendPDFByEmail());
    }
    dispatch(getManifiestos());
  };

  const handleRevisarManifiesto = (manifiesto: ManifiestoWithRecolecciones) => {
    setManifiestoToEdit(manifiesto);
    setIsEditorOpen(true);
  };
  const handleDesaprobar = (manifiesto: any) => {
    dispatch(aprobarManifiesto({ ...manifiesto, aprobado: false })).then(() => {
      notification.success({ message: "Desaprobado" });
      dispatch(getManifiestos());
    });
  };

  const rehacerPDF = (manifiesto: any) => {
    // TODO
  };

  /**COLUMNS LOGIC */
  const sortingConfiguration = (
    column: TableColumnType<ManifiestoWithRecolecciones>
  ) => {
    return {
      className: "header-sorter",
      onClick: async () => {
        if (orders.length === 0) return;
        setDirection((val) => (val === "ASC" ? "DESC" : "ASC"));
        await dispatch(setOrderByState(`${column.dataIndex}`, direction));
        dispatch(getManifiestos());
      },
    };
  };

  const BASE_COLUMNS = [
    {
      title: "Folio",
      width: 60,
      dataIndex: "folio",
      key: "folio",
      onHeaderCell: sortingConfiguration,
    },
    {
      title: "Fecha y hora",
      width: 100,
      dataIndex: "fechaHora",
      key: "fechaHora",
      onHeaderCell: sortingConfiguration,
      render: (fechaHora: string) => moment(fechaHora).format("LLL"),
    },
    {
      title: "Día",
      width: 100,
      dataIndex: "fechaHora",
      key: "diaSemana",
      onHeaderCell: sortingConfiguration,
      render: (fechaHora: string) => moment(fechaHora).format("dddd"),
    },
    {
      title: "No. Cliente",
      width: 80,
      dataIndex: "codigoCliente",
      key: "codigoCliente",
      onHeaderCell: sortingConfiguration,
    },
    {
      title: "Cliente",
      className: "client-name-cell collapsed",
      dataIndex: "nombreCliente",
      key: "nombreCliente",
      render: (nombre: string) => <Tooltip title={nombre}>{nombre}</Tooltip>,
    },
    {
      title: "Ruta",
      dataIndex: "ruta",
      key: "ruta",
      onHeaderCell: sortingConfiguration,
      render: (ruta: string) => <Tooltip title={ruta}>{ruta}</Tooltip>,
    },
    {
      title: "Vehiculo",
      dataIndex: "codigoVehiculo",
      key: "codigoVehiculo",
      onHeaderCell: sortingConfiguration,
      render: (vehiculo: string) => (
        <Tooltip title={vehiculo}>{vehiculo}</Tooltip>
      ),
    },
    {
      title: "Chofer",
      dataIndex: "chofer",
      key: "chofer",
      onHeaderCell: sortingConfiguration,
      render: (chofer: string) => <Tooltip title={chofer}>{chofer}</Tooltip>,
    },
    {
      title: "Domicilio",
      className: "domicilio-cell collapsed",
      dataIndex: "domicilio",
      key: "domicilio",
      render: (domicilio: string) => (
        <Tooltip title={domicilio}>{domicilio}</Tooltip>
      ),
    },
    {
      dataIndex: "recoleccions",
      title: "Items recolectados",
      key: "recoleccions",
      render: (recolecciones: any[]) => {
        return (
          <div>
            {recolecciones.map((recoleccion, index) => (
              <p key={index}>
                {`${recoleccion.cantidad} ${recoleccion.codigo_producto} ${recoleccion.size}`}
              </p>
            ))}
          </div>
        );
      },
    },
    {
      title: "Comentarios",
      dataIndex: "observaciones",
      key: "comentarios",
      onHeaderCell: sortingConfiguration,
      render: (comentarios: string) => (
        <Tooltip title={comentarios}>{comentarios}</Tooltip>
      ),
    },
    {
      title: "Comentarios internos",
      dataIndex: "comentariosInternos",
      key: "comentariosInternos",
      onHeaderCell: sortingConfiguration,
      render: (comentarios: string) => (
        <Tooltip title={comentarios}>{comentarios}</Tooltip>
      ),
    },
    {
      title: "Registro de cambios",
      dataIndex: "registroCambios",
      key: "registroCambios",
      onHeaderCell: sortingConfiguration,
      render: (comentarios: string) => (
        <Tooltip title={comentarios}>{comentarios}</Tooltip>
      ),
    },
    {
      title: "Enviado",
      width: 85,
      dataIndex: "emailEnviado",
      key: "emailEnviado",
      onHeaderCell: sortingConfiguration,
      render: (emailEnviado: boolean) => {
        return (
          <div>
            {emailEnviado ? (
              <BsCheck className="m-auto" color="green" />
            ) : (
              <BsX className="m-auto" color="red" />
            )}
          </div>
        );
      },
    },
    {
      title: "PDF Creado",
      width: 85,
      dataIndex: "pdfCreado",
      key: "pdfCreado",
      onHeaderCell: sortingConfiguration,
      render: (pdfCreado: boolean) => {
        return (
          <div>
            {pdfCreado ? (
              <BsCheck className="m-auto" color="green" />
            ) : (
              <BsX className="m-auto" color="red" />
            )}
          </div>
        );
      },
    },
    {
      title: "Acciones",
      className: "text-center",
      key: "approved-actions",
      render: (_: any, record: any) => (
        <div className="flex justify-center gap-1">
          <button
            onClick={() => handleSendEmails(record)}
            className="bg-green-700 text-white hover:bg-green-600  p-2 rounded-md "
          >
            <BsEnvelope />
          </button>
          <button
            onClick={() => rehacerPDF(record)}
            className="bg-green-700 text-white hover:bg-green-600  p-2 rounded-md "
          >
            <BsRecycle />
          </button>
          <button
            onClick={() => viewPDF(record)}
            className="bg-red-600 text-white hover:bg-red-500  p-2 rounded-md "
          >
            <BsEye />
          </button>
          <button
            onClick={() => handleDownloadPDF(record, true)}
            className="bg-indigo-600 text-white hover:bg-indigo-500  p-2 rounded-md "
          >
            <BsDownload />
          </button>
          <button
            onClick={() => handleDownloadPDF(record, false)}
            className="bg-slate-600 text-white hover:bg-slate-500  p-2 rounded-md "
          >
            <BsPrinter />
          </button>
          <Tooltip title="desaprobar">
            <button
              onClick={() => handleDesaprobar(record)}
              className="bg-yellow-600 text-white hover:bg-yellow-500  p-2 rounded-md "
            >
              <BsPersonX />
            </button>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Acciones",
      className: "text-center",
      key: "non-approved-actions",
      render: (_: any, record: ManifiestoWithRecolecciones) => (
        <Tooltip title={"Revisar manifiesto"}>
          <button
            onClick={() => handleRevisarManifiesto(record)}
            className="bg-indigo-600 text-white hover:bg-indigo-500  p-2 rounded-md"
          >
            <BsSearch />
          </button>
        </Tooltip>
      ),
    },
  ];

  const hiddenColumns: string[] = showAprobados
    ? [...userHiddenColumns, "non-approved-actions"]
    : [...userHiddenColumns, "pdfCreado", "emailEnviado", "approved-actions"];

  const tableColumns = BASE_COLUMNS.filter(
    (column) => !hiddenColumns.includes(column.key)
  );

  return (
    <div className="mb-10">
      <Modal
        open={isSendingEmails}
        closable={false}
        footer={<></>}
        destroyOnClose
      >
        Enviando emails ({Math.round(emailsPercent)} %)
        <Progress percent={emailsPercent} showInfo={false} />
      </Modal>
      {isCreatorOpen && (
        <Modal
          open={isCreatorOpen}
          closable={true}
          onCancel={() => setIsCreatorOpen(false)}
          destroyOnClose
          footer={<></>}
          title="Crear nuevo manifiesto"
        >
          <CreadorManifiestos
            onFinish={() => {
              setIsCreatorOpen(false);
            }}
          />
        </Modal>
      )}
      {isEditorOpen && manifiestoToEdit && (
        <Modal
          open={isEditorOpen}
          closable={true}
          onCancel={() => setIsEditorOpen(false)}
          destroyOnClose
          footer={<></>}
          title="Revisión"
        >
          <EditorManifiestos
            manifiesto={{ ...manifiestoToEdit }}
            onFinishEdit={() => setIsEditorOpen(false)}
          />
        </Modal>
      )}
      {isExcelReportGeneratorOpen && (
        <Modal
          open={isExcelReportGeneratorOpen}
          closable={true}
          onCancel={() => setIsExcelReportGeneratorOpen(false)}
          destroyOnClose
          footer={<></>}
          title={"Reporte excel"}
          width={600}
        >
          <ExcelReportGenerator />
        </Modal>
      )}
      {showColumnSelector && (
        <Modal
          open={showColumnSelector}
          closable={true}
          onCancel={() => setShowColumnSelector(false)}
          destroyOnClose
          footer={<></>}
          title={"Selecciona las columnas a mostrar"}
          width={600}
        >
          <ColumnsSelector
            initialColumns={allOptions.filter(
              (option) => !hiddenColumns.includes(option)
            )}
            onSubmit={(uhc: string[]) => {
              setUserHiddenColumns(uhc);
              setShowColumnSelector(false);
            }}
            location={location}
          />
        </Modal>
      )}
      <Button
        onClick={() => setIsCreatorOpen(true)}
        className={`float-left mb-2 flex align-middle justify-center text-white bg-green-800 hover:text-green-800 hover:bg-white hover:border-green-800`}
      >
        <BsDownload className="mr-1 mt-1" />
        Crear nuevo manifiesto
      </Button>
      <Button
        onClick={() => setShowColumnSelector(true)}
        className={`float-left mb-2 flex align-middle justify-center text-white bg-slate-800  hover:text-slate-900 hover:bg-white hover:border-slate-800`}
      >
        <BsTable className="mr-1 mt-1" />
        Elegir columnas
      </Button>
      {showAprobados && (
        <>
          <Button
            onClick={handleDownloadAll}
            loading={isLoadingPDF}
            className={`float-right mb-2 flex align-middle justify-center ml-2 text-white ${
              !hasManifiestos
                ? "bg-gray-400"
                : "bg-indigo-800  hover:text-indigo-800 hover:bg-white hover:border-indigo-800"
            }`}
            disabled={!hasManifiestos}
          >
            <BsDownload className="mr-1 mt-1" />
            Descargar todos
          </Button>
          <Button
            loading={isSendingEmails}
            disabled={!hasManifiestos}
            onClick={() => handleSendEmails()}
            className={`float-right mb-2 flex align-middle justify-center ml-2 text-white ${
              !hasManifiestos
                ? "bg-gray-400"
                : "bg-yellow-600  hover:text-yellow-800 hover:bg-white hover:border-yellow-800"
            }`}
          >
            <BsEnvelope className="mr-1 mt-1" />
            Enviar mails
          </Button>
          <Button
            onClick={() => setIsExcelReportGeneratorOpen(true)}
            disabled={!hasManifiestos}
            className={`float-right mb-2 flex align-middle justify-center text-white ${
              !hasManifiestos
                ? "bg-gray-400"
                : "bg-green-800  hover:text-green-900 hover:bg-white hover:border-green-800"
            }`}
          >
            <BsFileExcelFill className="mr-1 mt-1" />
            Descargar Excel
          </Button>
        </>
      )}

      <Table
        loading={isLoading}
        columns={tableColumns}
        showSorterTooltip={false}
        pagination={{
          current: currentPage + 1,
          total: manifiestosCount,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: handlePageChange,
        }}
        dataSource={orders}
      />
    </div>
  );
};

export default ManifiestosTable;
