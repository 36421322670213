import { Button, DatePicker, Form, message, Modal, Select, Table } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { API_BASE_URL, DRIVIN_BASE_URL } from "../../../utils/api";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getVehiculos } from "../../../redux/actions/vehiculos.actions";
import BotonAtras from "../../../components/BotonAtras";
import moment, { Moment } from "moment";
import { BsEye } from "react-icons/bs";
import Fotos from "./Fotos";

const VisorFinViaje = () => {
  const dispatch = useAppDispatch();
  const { vehiculos, isLoading: vehiculosLoading } = useAppSelector(
    (state) => state.vehiculos
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [conductoresLoading, setConductoresLoading] = useState(false);
  const [conductores, setConductores] = useState<any[]>([]);

  const [data, setData] = useState();
  const [dataLoading, setDataLoading] = useState(false);

  const [rowSelected, setRowSelected] = useState<{
    fecha: string;
    unidad: string;
    kilometraje: number;
  }>({ fecha: "", unidad: "", kilometraje: 0 });

  const getConductores = async () => {
    setConductoresLoading(true);
    try {
      const response = await fetch(
        `${DRIVIN_BASE_URL}/users?role_name=driver`,
        {
          headers: {
            "content-type": "application/json",
            "X-API-Key": "e9093bb7-3d24-4e53-87de-7f84b789cf06",
          },
        }
      );
      const driversRaw = await response.json();
      const sortedDrivers = driversRaw.response.sort((a: any, b: any) =>
        a.first_name.localeCompare(b.first_name)
      );
      const drivers: { label: string; value: any }[] = sortedDrivers.map(
        (res: any) => ({
          label: `${res.first_name} ${res.last_name}`,
          value: `${res.first_name} ${res.last_name}`,
        })
      );
      setConductores(drivers);
      setConductoresLoading(false);
    } catch (error) {
      console.error(error);
      message.error(`${error}`, 5);
      setConductoresLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getVehiculos());
    getConductores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChecklists = async (formData: {
    fecha: Moment;
    unidad: string;
    chofer: string;
  }) => {
    setDataLoading(true);
    const { unidad, fecha, chofer } = formData;
    try {
      let URL = `${API_BASE_URL}/vehiculos/kilometrajes?`;
      fecha && (URL += `fecha=${fecha.format("YYYY-MM-DD")}&`);
      unidad && (URL += `unidad=${unidad}&`);
      chofer && (URL += `chofer=${chofer}&`);
      const response = await fetch(URL);
      const kilometrajesDB = await response.json();
      setData(kilometrajesDB.rows);
    } catch (error) {}
    setDataLoading(false);
  };

  return (
    <div className="p-5">
      {modalOpen && (
        <Modal
          title="Fotos"
          width={1000}
          closable
          open={modalOpen}
          onCancel={() => setModalOpen(false)}
          okButtonProps={{ style: { display: "none" } }}
        >
          <Fotos {...rowSelected} />
        </Modal>
      )}
      <div className="flex gap-2">
        <BotonAtras />
        <h2 className="text-2xl text-green-900">Fotos fin de viaje</h2>
      </div>
      <Form className="mb-2" layout="inline" onFinish={getChecklists}>
        <Form.Item label="Fecha" name="fecha">
          <DatePicker
            disabledDate={(currentDate) => currentDate > dayjs()}
            allowClear={true}
            showTime={false}
            className="w-96"
          />
        </Form.Item>
        <Form.Item label="Unidad" name="unidad">
          <Select
            allowClear
            loading={vehiculosLoading}
            options={vehiculos.map((vehiculo: any) => ({
              label: vehiculo.id,
              value: vehiculo.id,
            }))}
            className="w-40"
          />
        </Form.Item>
        <Form.Item label="Chofer" name="chofer">
          <Select
            allowClear
            loading={conductoresLoading}
            options={conductores}
            className="w-96"
          />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Buscar
        </Button>
      </Form>

      <Table
        showSorterTooltip={false}
        loading={dataLoading}
        dataSource={data}
        columns={[
          {
            dataIndex: "fecha",
            key: "fecha",
            title: "Fecha",
            render: (value) => moment(value).format("LL"),
            sorter: (a, b) => a.fecha.localeCompare(b.fecha),
          },
          {
            dataIndex: "idVehiculo",
            key: "idVehiculo",
            title: "Unidad",
            sorter: (a, b) => a.idVehiculo.localeCompare(b.idVehiculo),
          },
          {
            dataIndex: "chofer",
            key: "chofer",
            title: "Chofer",
            sorter: (a, b) => a.chofer.localeCompare(b.chofer),
          },
          {
            dataIndex: "kilometraje",
            key: "kilometraje",
            title: "Kilometraje",

            sorter: (a, b) => a.kilometraje - b.kilometraje,
          },
          {
            dataIndex: "fecha",
            key: "fecha",
            title: "Acciones",
            render: (value, record, index) => {
              return (
                <div>
                  <Button
                    type="primary"
                    onClick={() => {
                      setModalOpen(true);
                      setRowSelected({
                        fecha: moment(value).format("DD-MM-YYYY"),
                        unidad: record.idVehiculo,
                        kilometraje: record.kilometraje,
                      });
                    }}
                  >
                    <BsEye />
                  </Button>
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default VisorFinViaje;
