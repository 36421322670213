import { Button, Card, Table } from "antd";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getVehiculos } from "../../../redux/actions/vehiculos.actions";
import ExpedienteSkeleton from "./ExpedienteSkeleton";
import { BsDownload } from "react-icons/bs";
import BotonAtras from "../../../components/BotonAtras";

const Expediente = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { isLoadingOne, currentVehiculo } = useAppSelector(
    (state) => state.vehiculos
  );

  useEffect(() => {
    dispatch(getVehiculos(id));
  }, [id, dispatch]);

  const descargarDocumento = (index: number) => {
    const documentos = ["refrendo", "tarjetaCirculacion", "polizaSeguro"];
    const url = `https://camionetas.s3.us-east-1.amazonaws.com/documentacion/${id}/${documentos[index]}`;
    var link = document.createElement("a");
    link.target = "_blank";
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="px-20 py-10">
      <h1 className="text-2xl text-green-900">Expediente unidad {id}</h1>
      <BotonAtras />

      {isLoadingOne ? (
        <ExpedienteSkeleton />
      ) : (
        <>
          <div className="flex flex-row w-full">
            <div className="w-1/4">
              <img
                width={200}
                className="img-responsive mx-auto mt-6"
                src={`https://camionetas.s3.us-east-1.amazonaws.com/documentacion/${id}/Frontal`}
                alt=""
              />
            </div>
            <div className="p-5 w-3/4 pr-0">
              <Card title="Información general" className="mb-5">
                <div className="grid grid-cols-3">
                  <div>
                    <b>Unidad</b>
                    <p>{id}</p>
                  </div>
                  <div>
                    <b>Marca</b>
                    <p>{currentVehiculo?.marca}</p>
                  </div>
                  <div>
                    <b>Año</b>
                    <p>{currentVehiculo?.modelo}</p>
                  </div>
                  <div>
                    <b>Placas</b>
                    <p>{currentVehiculo?.placas}</p>
                  </div>
                  <div>
                    <b>Número de serie</b>
                    <p>{currentVehiculo?.niv}</p>
                  </div>
                  <div>
                    <b>Número de motor</b>
                    <p>{currentVehiculo?.motor}</p>
                  </div>
                  <div>
                    <b>Nombre en la factura</b>
                    <p>{currentVehiculo?.nombreFactura}</p>
                  </div>
                  <div>
                    <Button
                      className="flex gap-2"
                      onClick={() => descargarDocumento(0)}
                    >
                      <BsDownload className="mt-1" />
                      Refrendo
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="flex gap-2"
                      onClick={() => descargarDocumento(1)}
                    >
                      <BsDownload className="mt-1" />
                      Tarjeta de circulación
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <Card title="Datos del seguro" className="mb-5">
            <div className="grid grid-cols-3">
              <div>
                <b>Compañia</b>
                <p>{currentVehiculo?.cia}</p>
              </div>
              <div>
                <b>No. de poliza</b>
                <p>{currentVehiculo?.poliza}</p>
              </div>
              <div>
                <b>No. de enciso</b>
                <p>{currentVehiculo?.enciso}</p>
              </div>
              <div>
                <b>Inicio de poliza</b>
                <p>{currentVehiculo?.fechaSeguroInicio}</p>
              </div>
              <div>
                <b>Fin de poliza</b>
                <p>{currentVehiculo?.fechaSeguroFin}</p>
              </div>

              <div>
                <Button
                  className="flex gap-2"
                  onClick={() => descargarDocumento(2)}
                >
                  <BsDownload className="mt-1" />
                  Poliza
                </Button>
              </div>
            </div>
            <h2>Siniestros</h2>
            <Table
              columns={[
                { title: "Fecha" },
                { title: "No. Siniestro" },
                { title: "Status" },
                { title: "Compañia" },
                { title: "Ajustador" },
                { title: "Celular" },
                { title: "Gastos" },
                { title: "Acciones" },
              ]}
            />
          </Card>

          <Card title="Reportes fallas mécanicas">
            <Table
              columns={[
                { title: "Fecha reporte" },
                { title: "Status" },
                { title: "Fecha atención" },
                { title: "¿Quién reporta?" },
                { title: "¿Entró a taller?" },
                { title: "Acciones" },
              ]}
            />
          </Card>

          <Card title="Multas">
            <Table
              columns={[
                { title: "Fecha" },
                { title: "Infractor" },
                { title: "Motivo" },
                { title: "Precio" },
                { title: "Descuentos activos" },
                { title: "Acciones" },
              ]}
            />
          </Card>
        </>
      )}
    </div>
  );
};

export default Expediente;
