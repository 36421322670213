import { Modal, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getVehiculos } from "../../../redux/actions/vehiculos.actions";
import { Vehiculo } from "../../../utils/types";
import { API_BASE_URL } from "../../../utils/api";
interface IProps {
  fecha: string;
  unidad: string;
  kilometraje: number;
}
const Fotos = ({ unidad, fecha, kilometraje }: IProps) => {
  const dispatch = useAppDispatch();
  const [unidadSelected, setUnidadSelected] = useState(unidad);
  const { isLoading, vehiculos } = useAppSelector((state) => state.vehiculos);
  useEffect(() => {
    dispatch(getVehiculos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeUnidad = (newUnidad: string) => {
    Modal.confirm({
      content: "¿Estas seguro que deseas cambiar la unidad de este dia?",
      onOk: async () => {
        setUnidadSelected(newUnidad);
        // Disparar change event con la unidad nueva y la unidad vieja
        // await fetch(`${API_BASE_URL}/vehiculos/kilometrajes`, {
        //   method: "PUT",
        //   headers: new Headers({ "content-type": "application/json" }),
        //   body: JSON.stringify({ fecha, unidad, nuevaUnidad: newUnidad }),
        // });

        //Mudar las fotos en S3

        notification.success({
          message: "Funcionalidad en proceso, aun no funciona",
        });
      },
      onCancel: () => {
        notification.warning({ message: "Sin modificaciones" });
      },
    });
  };

  const fotosLabels = [
    "Kilometraje",
    "Parte frontal",
    "Parte trasera",
    "Parte derecha",
    "Parte izquierda",
    "Caja abierta",
    "Cabina limpia",
    "Atras de sillones",
  ];
  return (
    <div>
      <div className="flex flex-row flex-nowrap gap-3 justify-center items-center">
        <b>Unidad</b>
        <div className="mx-2">
          <Select
            onChange={changeUnidad}
            value={unidadSelected}
            loading={isLoading}
            options={vehiculos.map((vehiculo: Vehiculo) => ({
              label: vehiculo.id,
              value: vehiculo.id,
            }))}
          />
        </div>
        <b>Fecha</b> <span className="mx-2"> {fecha}</span>
        <b>Kilometraje</b>
        <span className="mx-2">{kilometraje}</span>
      </div>

      <div className="grid grid-cols-3">
        {fotosLabels.map((label) => (
          <div className="text-center">
            <label className="text-xl">{label}</label>
            <a
              href={`https://camionetas.s3.us-east-1.amazonaws.com/FIN_VIAJE/${unidad}/${fecha}/${label}.jpg`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`https://camionetas.s3.us-east-1.amazonaws.com/FIN_VIAJE/${unidad}/${fecha}/${label}.jpg`}
                alt=""
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Fotos;
