import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Navbar from "../components/Navbar/Navbar";
import { Modal, notification } from "antd";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { openPDFViewer } from "../redux/actions/manifiestos.actions";
import PDFViewer from "../components/PDFViewer/PDFViewer";
import { openClientEditor } from "../redux/actions/client.actions";
import ClientEditor from "../components/ClientEditor/ClientEditor";
import MobileNavbar from "../components/Navbar/MobileNavbar";

const RootLayout = () => {
  const [api, contextHolder] = notification.useNotification();
  const isLargeScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const dispatch = useAppDispatch();
  const [showEmailErrors, setShowEmailErrors] = useState(false);
  const isPDFViewerOpen = useAppSelector(
    (state) => state.manifiestos.isPDFViewerOpen
  );
  const isClientEditorOpen = useAppSelector(
    (state) => state.clients.isClientEditorOpen
  );

  const clientEditing = useAppSelector(
    (state) => state.clients.clientIdEditing
  );
  const clientNameEditing = useAppSelector(
    (state) => state.clients.clientNameEditing
  );
  const hasErrors = useAppSelector(
    (state) =>
      state.clients.hasErrors ||
      state.manifiestos.hasErrors ||
      state.formulariosErrores.hasErrors
  );
  const errors = useAppSelector((state) => {
    const errors = [];
    errors.push(...state.clients.errors);
    errors.push(...state.manifiestos.errors);
    errors.push(...state.formulariosErrores.errors);
    return errors;
  });

  const emailErrors = useAppSelector((state) => state.manifiestos.emailErrors);

  useEffect(() => {
    if (emailErrors && emailErrors.length > 0) {
      setShowEmailErrors(true);
    }
  }, [emailErrors]);

  useEffect(() => {
    if (hasErrors) {
      const errorMessage = errors.reduce((accum, error) => {
        accum += error + ".";
        return accum;
      }, "");
      api.error({
        message: `Error`,
        description: `${errorMessage}`,
        placement: "topRight",
      });
      dispatch({ type: "RESET-ERRORS" });
    }
  }, [hasErrors, api, errors, dispatch]);

  return (
    <div className={`flex ${isLargeScreen ? "flex-row" : "flex-col"}`}>
      {contextHolder}
      {isLargeScreen ? <Navbar /> : <MobileNavbar />}
      <div className="w-full h-screen overflow-y-scroll">
        <Modal
          width="1250px"
          title={"PDF Viewer"}
          open={isPDFViewerOpen}
          onCancel={() => dispatch(openPDFViewer({ open: false }))}
          onOk={() => dispatch(openPDFViewer({ open: false }))}
          destroyOnClose={true}
        >
          <PDFViewer />
        </Modal>
        {isClientEditorOpen && (
          <Modal
            width="1250px"
            title={"Editando al cliente: " + clientEditing}
            open={isClientEditorOpen}
            onCancel={() =>
              dispatch(
                openClientEditor({ open: false, clientId: "", clientName: "" })
              )
            }
            onOk={() =>
              dispatch(
                openClientEditor({ open: false, clientId: "", clientName: "" })
              )
            }
            destroyOnClose={true}
            footer={<></>}
          >
            <ClientEditor
              clientId={clientEditing || ""}
              clientName={clientNameEditing || ""}
            />
          </Modal>
        )}
        {showEmailErrors && (
          <Modal
            width="450px"
            title={"Errores al mandar mails: "}
            open={showEmailErrors}
            onCancel={() => setShowEmailErrors(false)}
            onOk={() => setShowEmailErrors(false)}
            destroyOnClose={true}
          >
            {emailErrors.map((error: string, idx: number) => (
              <div className="block" key={idx}>
                {error}
              </div>
            ))}
          </Modal>
        )}
        <Outlet />
      </div>
    </div>
  );
};

export default RootLayout;
