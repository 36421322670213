import moment from "moment";
import DrivinApi from "./api";

const getRouteDriver = async (order_code: string, fecha: string) => {
  try {
    const pod = await DrivinApi.getPruebaEntrega([order_code], fecha);
    return {
      route: pod[0].route_code,
      driver: pod[0].driver_name,
      asistant: pod[0].assistant_1_name,
    };
  } catch (error) {
    return {
      route: "",
      driver: "",
      asistant: "",
    };
  }
};

const formatManifiestoPayload = async (dataFormulario: any) => {
  try {
    let payloadForManifiesto = {
      folio: "",
      codigoCliente: "",
      ruta: "",
      chofer: "",
      asistente: "",
      codigoVehiculo: "",
      nombreCliente: "",
      fechaHora: "",
      fotos: "",
      nombreFirma: "",
      firma: "",
      observaciones: "",
      recibioDinero: false,
      dinero: 0,
    };

    if (dataFormulario.extra_data) {
      const extraData = dataFormulario.extra_data;
      const routeInformation = await getRouteDriver(
        extraData.order_data[0].order_code,
        moment(dataFormulario.date_time).format("YYYY-MM-DD")
      );
      payloadForManifiesto = {
        ...payloadForManifiesto,
        folio: extraData.order_data[0].order_code,
        codigoCliente: extraData.address_code,
        ruta: routeInformation.route,
        chofer: routeInformation.driver,
        asistente: routeInformation.asistant,
        codigoVehiculo: extraData.vehicle_code,
        nombreCliente: extraData.address_name,
        fotos: JSON.stringify(dataFormulario.image_url),
        firma: dataFormulario.signature_url[0],
        fechaHora: dataFormulario.date_time,
      };
    }
    const payloadForRecolecciones: any[] = [];
    dataFormulario.form_data &&
      dataFormulario.form_data.forEach((form: any) => {
        if (form.code === "REC") {
          const recoleccion = {
            cantidad: form.forms[0].value.value_text,
            codigo_producto: form.forms[1].value.value_options[0].value,
            size: form.forms[2].value.value_options[0].value,
          };
          payloadForRecolecciones.push(recoleccion);
        } else if (form.code === "Firma") {
          payloadForManifiesto.recibioDinero = form.forms[0].value?.value_bool;
          payloadForManifiesto.dinero = form.forms[0].observation || 0;
          payloadForManifiesto.nombreFirma =
            form.forms[1].value?.value_text || "";
          payloadForManifiesto.observaciones =
            form.forms[2].value?.value_text || "";
        }
      });

    return { ...payloadForManifiesto, recoleccions: payloadForRecolecciones };
  } catch (error) {
    throw error;
  }
};

export default formatManifiestoPayload;
