import { Vehiculo } from "../../utils/types";
import { ActionTypes } from "../actions/vehiculos.actions";

interface IReducer {
  isLoading: boolean;
  isLoadingOne: boolean;
  isSaveLoading: boolean;
  isDeleteLoading: boolean;
  hasErrors: boolean;
  errors: string[];
  vehiculos: Vehiculo[];
  currentVehiculo?: Vehiculo;
  wizardStep: number;
}

const initialState: IReducer = {
  isLoading: false,
  isLoadingOne: false,
  isSaveLoading: false,
  isDeleteLoading: false,
  hasErrors: false,
  errors: [],
  vehiculos: [],
  currentVehiculo: undefined,
  wizardStep: 0,
};

const VehiculosReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // FETCH ONE
    case ActionTypes.START_FETCH_ONE_VEHICULO:
      return {
        ...state,
        isLoadingOne: true,
        currentVehiculo: null,
        hasErrors: false,
        errors: [],
      };
    case ActionTypes.SUCCESS_FETCH_ONE_VEHICULO:
      return {
        ...state,
        isLoadingOne: false,
        currentVehiculo: action.payload,
      };
    case ActionTypes.FAILED_FETCH_ONE_VEHICULO:
      return {
        ...state,
        isLoadingOne: false,
        hasErrors: true,
        errors: action.payload,
      };
    // FETCH ALL
    case ActionTypes.START_FETCH_VEHICULOS:
      return {
        ...initialState,
        isLoading: true,
        hasErrors: false,
        errors: [],
        vehiculos: [],
      };
    case ActionTypes.SUCCESS_FETCH_VEHICULOS:
      return {
        ...state,
        isLoading: false,
        vehiculos: action.payload.rows,
      };
    case ActionTypes.FAILED_FETCH_VEHICULOS:
      return {
        ...state,
        isLoading: false,
        hasErrors: true,
        errors: action.payload,
      };
    // DELETE
    case ActionTypes.START_DELETE_VEHICULO:
      return {
        ...state,
        isDeleteLoading: true,
      };
    case ActionTypes.SUCCESS_DELETE_VEHICULO:
      return {
        ...state,
        isDeleteLoading: false,
      };
    case ActionTypes.FAILED_DELETE_VEHICULO:
      return {
        ...state,
        isDeleteLoading: false,
        hasErrors: true,
        errors: action.payload,
      };
    // SAVE
    case ActionTypes.START_SAVE_VEHICULO:
      return {
        ...state,
        isSaveLoading: true,
      };
    case ActionTypes.SUCCESS_SAVE_VEHICULO:
      return {
        ...state,
        isSaveLoading: false,
        currentVehiculo: action.payload,
      };
    case ActionTypes.FAILED_SAVE_VEHICULO:
      return {
        ...state,
        isSaveLoading: false,
        hasErrors: true,
        errors: action.payload,
      };
    case ActionTypes.SET_WIZARD_STEP:
      return {
        ...state,
        wizardStep: action.payload,
      };
    default:
      return state;
  }
};

export default VehiculosReducer;
