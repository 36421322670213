import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/Logo.svg";
import { useAppSelector } from "../../hooks";
import "./Navbar.css";
import { PAGES } from "./CommonNavbar";
import { BsCaretDown, BsCaretRight } from "react-icons/bs";
const MobileNavbar = () => {
  const [pageSelected, setPageSelected] = useState("home");
  const [subMenuExpanded, setSubMenuExpanded] = useState(
    new Array(PAGES.length).fill(false)
  );
  const usuario = useAppSelector((state) => state.auth.usuario);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <div>
      <nav id="ecomexico-navbar">
        <div className="p-2">
          <Link to={"/"} className="flex items-center gap-2">
            <img src={logo} className="h-10 mx-auto" alt="Ecomexico Logo" />
            <div>
              <p className="self-center text-3xl font-semibold whitespace-nowrap text-green-100 mb-0">
                Ecomexico
              </p>
              <span className="self-center whitespace-nowrap text-green-50">
                {usuario}
              </span>
            </div>
          </Link>
        </div>
        <div>
          <div className="flex items-center h-full mr-2">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              type="button"
              className=" text-green-100 rounded-lg"
            >
              <svg
                className="w-10 h-10"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </nav>
      <div className={isMenuOpen ? "" : "hidden"}>
        <div className="block w-full bg-green-700" id="navbar-default">
          <ul className="flex flex-col p-4 mt-4 text-green-50 w-full">
            {PAGES.map((page, idx) => {
              return (
                <li key={idx}>
                  <div className="flex items-center">
                    {page.subMenu?.length && (
                      <div
                        onClick={() => {
                          const smExpanded = [...subMenuExpanded];
                          smExpanded[idx] = !smExpanded[idx];
                          setSubMenuExpanded(smExpanded);
                        }}
                      >
                        {subMenuExpanded[idx] ? (
                          <BsCaretDown />
                        ) : (
                          <BsCaretRight />
                        )}
                      </div>
                    )}
                    <Link
                      to={page.ref}
                      onClick={() => {
                        setPageSelected(page.id);
                        setIsMenuOpen(false);
                      }}
                      className={`text-xl block py-2 px-2 text-green-100 w-full ${
                        page.id === pageSelected
                          ? "text-green-300"
                          : "text-green-100"
                      } hover:bg-green-600 rounded`}
                      aria-current="page"
                    >
                      <div className="flex flex-row w-auto p-2">
                        <div className="flex items-center pr-2">
                          {page.icon}
                        </div>
                        {page.title}
                      </div>
                    </Link>
                  </div>

                  <div className={subMenuExpanded[idx] ? "" : "hidden"}>
                    {page.subMenu?.map((subPage) => (
                      <Link
                        to={subPage.ref}
                        onClick={() => {
                          setPageSelected(subPage.id);
                          setIsMenuOpen(false);
                        }}
                        className={`text-md block pb-1 ml-6 text-green-200 w-3/4 border-l-2 ${
                          subPage.id === pageSelected
                            ? "text-green-300"
                            : "text-green-100"
                        } hover:bg-green-600`}
                      >
                        <div className="flex flex-row w-auto p-2">
                          <div className="flex items-center pr-2">
                            {subPage.icon}
                          </div>
                          {subPage.title}
                        </div>
                      </Link>
                    ))}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
