import { useState } from "react";

import { Link } from "react-router-dom";
import logo from "../../assets/Logo.svg";
import { Button } from "antd";
import { Auth } from "aws-amplify";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { logout } from "../../redux/actions/login.actions";
import { PAGES } from "./CommonNavbar";
import {
  BsArrowLeft,
  BsArrowRight,
  BsCaretDown,
  BsCaretRight,
  BsPower,
} from "react-icons/bs";

const Navbar = () => {
  const [pageSelected, setPageSelected] = useState("home");
  const [subMenuExpanded, setSubMenuExpanded] = useState(
    new Array(PAGES.length).fill(false)
  );
  const usuario = useAppSelector((state) => state.auth.usuario);
  const [collapsed, setCollapsed] = useState(false);

  const dispatch = useAppDispatch();
  const handleLogout = () => {
    Auth.signOut().then(() => {
      dispatch(logout());
    });
  };
  return (
    <nav
      className={`bg-green-700 pt-2.5 h-screen flex flex-col justify-between  ${
        collapsed ? "w-20" : "w-60"
      }`}
    >
      <div
        className={`flex flex-wrap flex-col items-center mx-auto ${
          collapsed ? "px-0 w-20" : "px-2 w-60"
        } `}
      >
        <div
          className={`mb-2 flex w-full ${
            collapsed ? "justify-center" : "justify-end"
          }`}
        >
          <Button
            className="text-white"
            type="text"
            onClick={() => {
              const subMenuPages = new Array(PAGES.length).fill(false);
              setSubMenuExpanded(subMenuPages);
              setCollapsed(!collapsed);
            }}
          >
            {collapsed ? <BsArrowRight /> : <BsArrowLeft />}
          </Button>
        </div>
        <Link to={"/"} className="items-center text-center">
          <img src={logo} className="h-9 mx-auto" alt="Ecomexico Logo" />
          <p
            className={`self-center text-3xl font-semibold whitespace-nowrap text-green-100 mb-0 ${
              collapsed ? "hidden" : ""
            }`}
          >
            Ecomexico
          </p>
          <span
            className={`self-center whitespace-nowrap text-green-50 ${
              collapsed ? "hidden" : ""
            }`}
          >
            {usuario}
          </span>
        </Link>

        <div className="block w-full" id="navbar-default">
          <ul className="flex flex-col p-4 mt-4 text-green-50 w-full">
            {PAGES.map((page, idx) => {
              return (
                <li key={idx}>
                  <div className="flex items-center">
                    {page.subMenu?.length && (
                      <div
                        onClick={() => {
                          const smExpanded = [...subMenuExpanded];
                          smExpanded[idx] = !smExpanded[idx];
                          setSubMenuExpanded(smExpanded);
                          setCollapsed(false);
                        }}
                      >
                        {subMenuExpanded[idx] ? (
                          <BsCaretDown />
                        ) : (
                          <BsCaretRight />
                        )}
                      </div>
                    )}
                    <Link
                      to={page.ref}
                      onClick={() => setPageSelected(page.id)}
                      className={`text-xl block py-2 ${
                        collapsed ? "px-0" : "px-2"
                      } text-green-100 w-full ${
                        page.id === pageSelected
                          ? "text-green-300"
                          : "text-green-100"
                      } hover:bg-green-600 rounded`}
                      aria-current="page"
                    >
                      <div className="flex flex-row w-auto p-2">
                        <div className="flex items-center pr-2">
                          {page.icon}
                        </div>
                        <span className={`${collapsed ? "hidden" : ""}`}>
                          {page.title}
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className={subMenuExpanded[idx] ? "" : "hidden"}>
                    {page.subMenu?.map((subPage) => (
                      <Link
                        to={subPage.ref}
                        onClick={() => setPageSelected(subPage.id)}
                        className={`text-md block pb-1 ml-6 text-green-200 w-3/4 border-l-2 ${
                          subPage.id === pageSelected
                            ? "text-green-300"
                            : "text-green-100"
                        } hover:bg-green-600`}
                      >
                        <div className="flex flex-row w-auto p-2">
                          <div className="flex items-center pr-2">
                            {subPage.icon}
                          </div>
                          {subPage.title}
                        </div>
                      </Link>
                    ))}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <Button
        className="bg-white text-green-800 rounded-none h-14 text-xl "
        onClick={handleLogout}
      >
        {collapsed ? <BsPower /> : "Cerrar sesión"}
      </Button>
    </nav>
  );
};

export default Navbar;
