import { Dispatch } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../utils/api";
import { notification } from "antd";

export const ActionTypes = {
  START_FETCH_FORMULARIOS_ERRORES: "START_FETCH_FORMULARIOS_ERRORES",
  SUCCESS_FETCH_FORMULARIOS_ERRORES: "SUCCESS_FETCH_FORMULARIOS_ERRORES",
  FAILED_FETCH_FORMULARIOS_ERRORES: "FAILED_FETCH_FORMULARIOS_ERRORES",

  START_SAVE_NEW_MANIFIESTO: "START_SAVE_NEW_MANIFIESTO",
  SUCCESS_SAVE_NEW_MANIFIESTO: "SUCCESS_SAVE_NEW_MANIFIESTO",
  FAILED_SAVE_NEW_MANIFIESTO: "FAILED_SAVE_NEW_MANIFIESTO",

  START_DELETE_FORMULARIO: "START_DELETE_FORMULARIO",
  SUCCESS_DELETE_FORMULARIO: "SUCCESS_DELETE_FORMULARIO",
  FAILED_DELETE_FORMULARIO: "FAILED_DELETE_FORMULARIO",
};

export const fetchFormulariosConErrores = () => async (dispatch: Dispatch) => {
  dispatch({
    type: ActionTypes.START_FETCH_FORMULARIOS_ERRORES,
  });
  try {
    const response = await fetch(`${API_BASE_URL}/formularios-errores`);
    if (response.status !== 200) {
      dispatch({
        type: ActionTypes.FAILED_FETCH_FORMULARIOS_ERRORES,
        payload: `${response.status} - ${response.statusText}`,
      });
      return;
    }
    const formularios = await response.json();
    const formulariosOnVisit = formularios.filter(
      (formulario: any) => formulario.payload.checklist_type === "on_visit"
    );
    formulariosOnVisit.sort((a: any, b: any) =>
      a.payload.extra_data.order_data[0].order_code >
      b.payload.extra_data.order_data[0].order_code
        ? 1
        : -1
    );

    dispatch({
      type: ActionTypes.SUCCESS_FETCH_FORMULARIOS_ERRORES,
      payload: formulariosOnVisit,
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILED_FETCH_FORMULARIOS_ERRORES,
      payload: error,
    });
  }
};

export const saveNewManifiesto =
  (formularioId: number, manifiestoPayload: any) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: ActionTypes.START_SAVE_NEW_MANIFIESTO });
    try {
      const response = await fetch(
        `${API_BASE_URL}/formularios-errores/save-new/${formularioId}`,
        {
          method: "POST",
          body: JSON.stringify(manifiestoPayload),
        }
      );
      const data = await response.json();
      if (response.status === 500 && data.ERR_CODE === "01") {
        notification.error({ message: "Folio repetido" });
        dispatch({
          type: ActionTypes.FAILED_SAVE_NEW_MANIFIESTO,
          payload: "Folio repetido",
        });
        return true;
      }
      if (response.status === 500 && data.ERR_CODE === "02") {
        notification.error({ message: "Cliente no dado de alta" });
        dispatch({
          type: ActionTypes.FAILED_SAVE_NEW_MANIFIESTO,
          payload: "Cliente no dado de alta",
        });
        return true;
      }
      dispatch({
        type: ActionTypes.SUCCESS_SAVE_NEW_MANIFIESTO,
        payload: data,
      });
      return false;
    } catch (error) {
      dispatch({
        type: ActionTypes.FAILED_SAVE_NEW_MANIFIESTO,
        payload: error,
      });
      return true;
    }
  };

export const deleteForm = (id: number) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionTypes.START_DELETE_FORMULARIO });
  try {
    await fetch(`${API_BASE_URL}/formularios-errores/${id}`, {
      method: "DELETE",
    });
    dispatch({
      type: ActionTypes.SUCCESS_DELETE_FORMULARIO,
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILED_DELETE_FORMULARIO,
      payload: error,
    });
  }
};
